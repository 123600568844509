/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// @flow
import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import groupBy from 'lodash.groupby';
import sortBy from 'lodash.sortby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Link } from 'gatsby';
import queryString from 'query-string';
import { Location } from '@reach/router';
import {
  Box,
  Container,
  Grid,
  Fade,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Icon,
  Collapse,
} from '@material-ui/core';
import arrowDownIcon from '../../../images/arrow-down.svg';
import searchIcon from '../../../images/icon-search-grey.svg';
import searchConsumerIcon from '../../../images/icon-search-pink.svg';
import { faqStyles } from './styles';

const FAQ = ({
  categories,
  answers,
  isConsumerFaq,
  location,
}: {
  categories: Array<Object>,
  answers: Array<Object>,
  isConsumerFaq?: boolean,
  location: Object,
}) => {
  const classes = faqStyles({ isConsumerFaq });
  const inputRef = useRef();
  const firstCategory = categories.length && categories[0].name;
  const mappedMerchantFaq = answers.map((item) => ({
    ...item,
    categoryName: item.category && item.category.name,
  }));
  const qa = groupBy(mappedMerchantFaq, 'categoryName');
  const [activeCategory, setActiveCategory] = useState(firstCategory);
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, toggleIsOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const currentSection = queryString.parse(location.search)?.section;

    if (currentSection) {
      const currentCategory = categories.find(
        ({ sectionName }) => sectionName === currentSection
      );

      if (currentCategory?.name) setActiveCategory(currentCategory.name);
    }
  }, [categories, location.search]);

  const onSetActiveCategory = (category) => {
    setActiveCategory(category);
    setSearchQuery(null);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setActiveCategory(null);
    const value = inputRef && inputRef.current && inputRef.current.value;

    if (value) {
      setSearchQuery(value);
    } else {
      setSearchQuery(null);
      setActiveCategory(activeCategory || firstCategory);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const SearchIcon = isConsumerFaq ? (
    <img
      src={searchConsumerIcon}
      alt="search"
      width="58"
      height="56"
      className={classes.cosumerInputIcon}
      onClick={onSubmit}
    />
  ) : (
    <img
      src={searchIcon}
      alt="search"
      width="25"
      height="23"
      onClick={onSubmit}
      className={classes.inputIcon}
    />
  );
  const searchClass = isConsumerFaq
    ? { root: classes.consumerRootInput }
    : { root: classes.input };
  const inputClass = isConsumerFaq
    ? { notchedOutline: classes.consumerInput }
    : null;

  const searchResults =
    searchQuery &&
    answers.filter(
      (item) =>
        item.question.includes(searchQuery) || item.answer.includes(searchQuery)
    );

  return (
    categories && (
      <Box className={classes.wrapper}>
        <Container className={classes.container}>
          <Grid container spacing={{ xs: 0, md: 6 }}>
            <Grid item xs={12} md={4} className={classes.prompt}>
              <form onSubmit={onSubmit}>
                <TextField
                  inputRef={inputRef}
                  classes={searchClass}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: <Icon>{SearchIcon}</Icon>,
                    classes: inputClass,
                  }}
                  placeholder="例「支払い方法」「分割」"
                />
              </form>
            </Grid>

            <Box my="50px" disableGutters width="100%" className={classes.sp}>
              <Box
                className={classes.select}
                disableGutters
                onClick={() => toggleIsOpen(!isOpen)}
              >
                {activeCategory}
                <div className={classes.selectIcon} />
              </Box>
              <Collapse in={isOpen}>
                <Box className={classes.selectContent}>
                  {categories.map((category, index) => (
                    <Link
                      className={cx(classes.selectItem, {
                        [classes.selectItemActive]:
                          category.name === activeCategory,
                      })}
                      value={category.name}
                      key={index}
                      to={`/faq/?section=${category.sectionName}`}
                      onClick={() => onSetActiveCategory(category.name)}
                    >
                      {category.name}
                    </Link>
                  ))}
                </Box>
              </Collapse>
            </Box>

            <Grid item md={8} className={classes.pc}></Grid>
            <Grid item md={4} className={classes.pc}>
              {categories.map((category, index) => (
                <Link
                  key={index}
                  to={`/faq/?section=${category.sectionName}`}
                  onClick={() => onSetActiveCategory(category.name)}
                >
                  <Box
                    className={cx(classes.title, {
                      [classes.titleActive]: category.name === activeCategory,
                    })}
                    component="div"
                  >
                    {category.name}
                  </Box>
                </Link>
              ))}
            </Grid>

            <Box clone mt={isConsumerFaq ? '-88px' : 0}>
              <Grid item xs={12} md={8} className={classes.faqList}>
                {!searchQuery &&
                  categories.map((category) => {
                    const blocks = sortBy(qa[category.name], 'order');

                    return (
                      <Box
                        key={`block-${category.name}`}
                        className={cx(classes.block, {
                          [classes.blockActive]:
                            category.name === activeCategory,
                        })}
                      >
                        <Fade
                          in={activeCategory === category.name}
                          timeout={1000}
                        >
                          <Box className={classes.innerBlock}>
                            {blocks.map((block, index) => (
                              <Accordion
                                key={index}
                                classes={{ root: classes.accordion }}
                                expanded={expanded === `panel-${index}`}
                                onChange={handleChange(`panel-${index}`)}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <img
                                      src={arrowDownIcon}
                                      alt="toggle-icon"
                                      width="32"
                                      height="32"
                                    />
                                  }
                                  aria-controls="q-content"
                                  id="a-header"
                                  classes={{
                                    root: classes.summary,
                                    content: classes.summaryContent,
                                  }}
                                >
                                  <Box display="flex" className={classes.faqs}>
                                    {isConsumerFaq ? (
                                      <span className={classes.cq}>Q</span>
                                    ) : (
                                      <span className={classes.q}>Q</span>
                                    )}
                                    <Typography
                                      variant="h6"
                                      component="div"
                                      className={classes.heading}
                                    >
                                      {block.question}
                                    </Typography>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                  classes={{ root: classes.details }}
                                >
                                  {isConsumerFaq && (
                                    <span className={classes.a}>A</span>
                                  )}
                                  <Typography
                                    variant="body1"
                                    dangerouslySetInnerHTML={{
                                      __html: block.answer,
                                    }}
                                  ></Typography>
                                </AccordionDetails>
                              </Accordion>
                            ))}
                          </Box>
                        </Fade>
                      </Box>
                    );
                  })}

                {searchQuery && (
                  <Box className={classes.innerBlock}>
                    {searchResults && searchResults.length ? (
                      searchResults.map((item, index) => (
                        <Accordion
                          key={index}
                          classes={{ root: classes.accordion }}
                          expanded={expanded === `q-${index}`}
                          onChange={handleChange(`q-${index}`)}
                        >
                          <AccordionSummary
                            expandIcon={
                              <img
                                src={arrowDownIcon}
                                alt="toggle-icon"
                                width="32"
                                height="32"
                              />
                            }
                            aria-controls="question-content"
                            id="answer-header"
                            classes={{
                              root: classes.summary,
                              content: classes.summaryContent,
                            }}
                          >
                            <Box display="flex" alignItems="center">
                              <span className={classes.q}>Q</span>
                              <Typography
                                variant="h6"
                                component="div"
                                className={classes.heading}
                              >
                                {item.question}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails classes={{ root: classes.details }}>
                            <Typography
                              variant="body1"
                              dangerouslySetInnerHTML={{ __html: item.answer }}
                            ></Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))
                    ) : (
                      <Box p={4}>
                        <Typography variant="h5" component="div">
                          質問が見つかりませんでした。
                        </Typography>
                        <Box
                          className={classes.noResultsLink}
                          component={OutboundLink}
                          href="https://support.paidy.com/hc/ja"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          お問い合わせフォームはこちら
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Box>
    )
  );
};

FAQ.defaultProps = {
  isConsumerFaq: false,
};

// $FlowFixMe
export default (props) => (
  <Location>
    {({ location }) => <FAQ location={location} {...props} />}
  </Location>
);
