// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';

import { CircularProgress, Box } from '@material-ui/core';
import { fetchConsumerFAQ } from '../../redux/consumer-faq';
import { fetchConsumerFAQCategoriesOrder } from '../../redux/consumer-faq-categories-order';
import FAQ from '../Merchant/FAQ/FAQ';

const FAQLoader = ({
  _fetchConsumerFAQ,
  _fetchConsumerFAQCategoriesOrder,
  consumerFaq,
  consumerFaqCategoriesOrder,
  isFetchingCat,
  isFetchingFaq,
}: {
  _fetchConsumerFAQ: Function,
  _fetchConsumerFAQCategoriesOrder: Function,
  consumerFaq: Array<Object>,
  consumerFaqCategoriesOrder: Array<Object>,
  isFetchingCat: boolean,
  isFetchingFaq: boolean,
}) => {
  useEffect(() => {
    _fetchConsumerFAQ();
    _fetchConsumerFAQCategoriesOrder();
  }, []);

  const sortedCategories =
    consumerFaqCategoriesOrder &&
    get(consumerFaqCategoriesOrder[0], 'order.orders').map((cat) => ({
      ...cat,
      name: cat.title,
    }));
  const normalizedConsumerFaq =
    consumerFaq &&
    consumerFaq.map((faq) => ({ ...faq, category: { name: faq.category } }));

  if (isFetchingCat || isFetchingFaq || !consumerFaq || !sortedCategories) {
    return (
      <Box
        width="100%"
        py="100px"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <FAQ
      categories={sortedCategories}
      answers={normalizedConsumerFaq}
      isConsumerFaq
    />
  );
};

const mapStateToProps = (state) => ({
  consumerFaq: state.consumerFaq.data,
  consumerFaqCategoriesOrder: state.consumerFaqCategoriesOrder.data,
  isFetchingCat: state.consumerFaqCategoriesOrder.isFetching,
  isFetchingFaq: state.consumerFaq.isFetching,
});

const mapDispatchToProps = (dispatch: Function) => ({
  _fetchConsumerFAQ: () => dispatch(fetchConsumerFAQ()),
  _fetchConsumerFAQCategoriesOrder: () =>
    dispatch(fetchConsumerFAQCategoriesOrder()),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(FAQLoader);
