/* eslint-disable jsx-a11y/media-has-caption */
// @flow
import React from 'react';
import Container from '@material-ui/core/Container';
import Layout from '../components/Layout/Layout';
import Hero from '../components/Merchant/shared/Hero';
import Bg from '../images/faq.jpg';
import BgSp from '../images/faq-sp.jpg';
import FAQLoader from '../components/FAQ/FAQLoader';
import styles from '../styles/pages/faq.module.scss';

const FaqPage = () => {
  return (
    <Layout>
      <div className={styles.pc}>
        <Hero title="よくあるご質問" bg={Bg} />
      </div>
      <div className={styles.sp}>
        <Hero title="よくあるご質問" bg={BgSp} />
      </div>
      <Container maxWidth="md" className={styles.container}>
        <FAQLoader />
      </Container>
    </Layout>
  );
};

// $FlowFixMe
export default FaqPage;
