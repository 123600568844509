/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import arrowDownIcon from '../../../images/arrow-down.svg';

export const faqStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '70px 0',
    '@media (max-width: 1024px)': {
      padding: '50px 0',
    },
  },
  container: {
    maxWidth: '1048px',
    '@media (max-width: 1024px)': {
      padding: 0,
      maxWidth: '100%',
    },
  },
  prompt: {
    padding: '24px',
    '@media (max-width: 1024px)': {
      padding: 'initial',
    },
  },
  sp: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
    },
  },
  pc: {
    display: 'block',
    padding: '24px',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  title: {
    font: `400 18px/32px ${theme.typography.fontFamily}`,
    marginBottom: '10px',
    cursor: 'pointer',
    color: '#101825',
  },
  titleActive: {
    fontWeight: 700,
  },
  faqList: {
    padding: '24px',
    '@media (max-width: 1024px)': {
      padding: 'initial',
    },
  },
  block: {
    display: 'none',
  },
  blockActive: {
    display: 'block',
  },
  innerBlock: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.2)',
    borderRadius: '16px',
  },
  summary: {
    padding: '0 26px',
    '@media (max-width: 1024px)': {
      alignItems: 'center',
    },
  },
  summaryContent: {
    margin: '32px 0 !important',
  },
  accordion: {
    margin: '0 !important',
    boxShadow: 'none',
    '&:before': {
      opacity: '1 !important',
    },
    '&:first-child': {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
    '&:last-child': {
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
    },
  },
  details: {
    background: ({ isConsumerFaq }) => (isConsumerFaq ? '#F8F8F9' : '#EBE6E2'),
    padding: '32px 46px',
    '@media (max-width: 1024px)': {
      wordBreak: 'break-all',
    },
  },
  faqs: {
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      alignItems: 'normal',
    },
  },
  q: {
    font: `400 16px/24px ${theme.typography.fontFamily}`,
    color: '#101825',
    width: 30,
    height: 30,
    background: '#F6F3F2',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    flex: 'none',
  },
  cq: {
    font: `700 30px/24px ${theme.typography.fontFamily}`,
    color: '#2A2E32',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginRight: 20,
    flex: 'none',
    '@media (max-width: 1024px)': {
      marginRight: 25,
      marginTop: 4,
    },
  },
  a: {
    font: `700 30px/24px ${theme.typography.fontFamily}`,
    color: '#E5258C',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginRight: 20,
    flex: 'none',
    marginLeft: -19,
    '@media (max-width: 1024px)': {
      font: `400 28px/24px ${theme.typography.fontFamily}`,
      marginRight: 25,
      marginTop: 4,
    },
  },
  input: {
    marginBottom: 0,
    '@media (max-width: 1024px)': {
      padding: '0 16px',
      width: '100%',
    },
  },
  selectIcon: {
    width: 26,
    height: 'auto',
    background: `url(${arrowDownIcon}) center center no-repeat`,
    backgroundSize: 'contain',
  },
  select: {
    padding: ({ isConsumerFaq }) => (isConsumerFaq ? '0 20px' : '0 50px'),
    backgroundColor: ({ isConsumerFaq }) =>
      isConsumerFaq ? '#fff' : '#F6F3F2',
    border: ({ isConsumerFaq }) =>
      isConsumerFaq ? '1px solid #C4CAD2' : 'none',
    margin: ({ isConsumerFaq }) => (isConsumerFaq ? '0 16px' : 0),
    borderRadius: '6px',
    height: ({ isConsumerFaq }) => (isConsumerFaq ? 40 : 60),
    lineHeight: ({ isConsumerFaq }) => (isConsumerFaq ? '40px' : '60px'),
    font: `700 16px/24px ${theme.typography.fontFamily} `,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 1024px)': {
      width: 'auto',
    },
    '@media (max-width: 374.98px)': {
      padding: ({ isConsumerFaq }) => (isConsumerFaq ? '0 15px' : '0 30px'),
      font: `700 14px/24px ${theme.typography.fontFamily} `,
    },
  },
  selectContent: {
    position: 'absolute',
    backgroundColor: '#fff',
    maxHeight: 'calc(100% - 150px)',
    overflowY: 'auto',
    left: ({ isConsumerFaq }) => (isConsumerFaq ? 20 : 0),
    right: ({ isConsumerFaq }) => (isConsumerFaq ? 20 : 0),
    boxShadow: ({ isConsumerFaq }) =>
      isConsumerFaq
        ? '0px 10px 20px rgba(81, 37, 37, 0.12)'
        : '0px 20px 20px rgba(81, 37, 37, 0.12)',
    zIndex: 10,
  },
  selectItem: {
    display: 'block',
    font: `400 16px/24px ${theme.typography.fontFamily} `,
    height: 64,
    lineHeight: '64px',
    padding: ({ isConsumerFaq }) => (isConsumerFaq ? '0 20px' : '0 50px'),
    color: '#101825',
    '@media (max-width: 1024px)': {
      overflowX: 'auto',
    },
    '@media (max-width: 374.98px)': {
      padding: ({ isConsumerFaq }) => (isConsumerFaq ? '0 15px' : '0 30px'),
      font: `400 14px/24px ${theme.typography.fontFamily} `,
    },
  },
  selectItemActive: {
    fontWeight: 700,
  },
  consumerRootInput: {
    marginBottom: 0,
    paddingRight: 20,
    position: 'relative',
    '@media (max-width: 1024px)': {
      margin: '0 16px',
      width: '92%',
    },
  },
  consumerInput: {
    borderWidth: 2,
    borderColor: '#C4CAD2',
  },
  cosumerInputIcon: {
    position: 'absolute',
    right: -20,
    zIndex: 2,
    cursor: 'pointer',
    top: -8,
    '@media (max-width: 1024px)': {
      top: -5,
    },
  },
  inputIcon: {
    cursor: 'pointer',
  },
  noResultsLink: {
    marginTop: 10,
    display: 'block',
    color: '#000',
    textDecoration: 'underline',
  },
}));
